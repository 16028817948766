<template>
  <div>
      <div class="card">
          <div class="card-header">
                <div class="card-title m-0">
                    <h3>Profil Fotoğrafı</h3>
                </div>
          </div>
          <div class="card-body">

            <div class="row mb-6">
                <div class="col-lg-6">
                    <div>
                        <img style="width:200px" :src="'https://api.ytmdanismanlik.com/'+profile.profileImageDirectory+profile.profileImage" alt="image" />
                    </div>
                    <div>
                        <input type="file" @change="uploadFile" ref="file">                        
                    </div>
                    
                </div>
            </div>

           
          </div>

          
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="submitFile()" class="btn btn-primary">
            <span class="indicator-label"> Kaydet </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        
    </div>
              
    
  </div>
</template>


  <script>
    import api from '@/core/services/ApiService'
    import Swal from "sweetalert2/dist/sweetalert2.js";
    
    

    export default {
        name:'Pricing',
        props:['profile'],
        
        data(){
            return {
                image:null,
                pricing:null
            }
        },
        methods:{

            uploadFile() {
                this.Images = this.$refs.file.files[0];
            },

            submitFile() {
                const formData = new FormData();
                formData.append('file', this.Images);
                const headers = { 'Content-Type': 'multipart/form-data' };
                api.post('/User/UploadImage/'+this.profile.userId, formData).then((res) => {
                    Swal.fire({
                        title:'Profil Fotoğrafı',
                        text:'Danışman profil fotoğrafı güncellendi',
                        icon:'success',
                    }).then(()=>{
                        window.location.reload();
                    })
                });
            },
        },

    }
      </script>

